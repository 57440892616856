import { SignedOut, SignIn } from "@clerk/remix"
import { getAuth } from "@clerk/remix/ssr.server"
import { Center, Flex, Grid, Image } from "@mantine/core"
import { LoaderFunctionArgs, redirect } from "@remix-run/server-runtime"

import background from "./auth-background.png"
import { Logo } from "@kiosk/audit/components/Logo/Logo"
import { routes } from "@kiosk/audit/utils/constants/routes"

import classes from "./WelcomePage.module.css"

export async function loader(args: LoaderFunctionArgs) {
  const { userId } = await getAuth(args)

  // If the user is already logged in, redirect them to the dashboard.
  if (userId) {
    return redirect("/")
  }

  return null
}

export default function WelcomePage() {
  return (
    <SignedOut>
      <Grid
        classNames={{
          root: classes.welcome__container,
          inner: classes.welcome__inner,
        }}
      >
        <Grid.Col span={6} className={classes["welcome__container--height"]}>
          <Flex>
            <Logo height={28} />
          </Flex>
          <Center className={classes["welcome__container--height"]}>
            <SignIn
              // Hide the sign-up button on login form
              afterSignOutUrl={routes.SIGN_IN.path}
              appearance={{
                elements: { footerAction: { display: "none" } },
              }}
              fallbackRedirectUrl={routes.REPORTS_LIST.path}
              forceRedirectUrl={routes.REPORTS_LIST.path}
            />
          </Center>
        </Grid.Col>
        <Grid.Col span={6} className={classes["welcome__container--height"]}>
          <Image
            alt="auth image"
            fit="cover"
            h="100%"
            radius="md"
            src={background}
          />
        </Grid.Col>
      </Grid>
    </SignedOut>
  )
}
